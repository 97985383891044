import moment from "moment-jalaali";
import {
  getEventsListAPI,
  getLastTransactionsAPI,
  getTransactionsCoordinatesAPI,
  getTransactionsListAPI,
  getTransactionsReportAPI,
  refundTransactionAPI,
} from "../../services/api/transaction";
import {
  GET_EVENTS_LIST_FAILURE,
  GET_EVENTS_LIST_STARTED,
  GET_EVENTS_LIST_SUCCESS,
  GET_LAST_TRANSACTIONS_FAILURE,
  GET_LAST_TRANSACTIONS_STARTED,
  GET_LAST_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_COORDINATES_FAILURE,
  GET_TRANSACTIONS_COORDINATES_STARTED,
  GET_TRANSACTIONS_COORDINATES_SUCCESS,
  GET_TRANSACTIONS_LIST_FAILURE,
  GET_TRANSACTIONS_LIST_STARTED,
  GET_TRANSACTIONS_LIST_SUCCESS,
  GET_TRANSACTIONS_REPORT_FAILURE,
  GET_TRANSACTIONS_REPORT_STARTED,
  GET_TRANSACTIONS_REPORT_SUCCESS,
  REFUND_TRANSACTION_FAILURE,
  REFUND_TRANSACTION_STARTED,
  REFUND_TRANSACTION_SUCCESS,
} from "../actionTypes";
import { numberWithCommas } from "../../utils/helpers";
// --------------------------------------------------------------------------------
export const getTransactionsListRequestAction = ({
  from,
  to,
  offset,
  limit,
  eventId,
  setCount,
}) => {
  return async (dispatch) => {
    dispatch(getTransactionsListStartedAction());
    try {
      let response = await getTransactionsListAPI({
        from,
        to,
        offset,
        limit,
        eventId,
      });
      if (response.status === "success") {
        let transactions = response.data.records.map((item) => ({
          ...item,
          date: moment(item.date).format("jYYYY/jMM/jDD - HH:mm"),
        }));
        dispatch(
          getTransactionsListSuccessAction({
            transactionsList: transactions,
          })
        );
        setCount(response.data.total);
      } else {
        dispatch(
          getTransactionsListFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      dispatch(getTransactionsListFailureAction(error.error_description));
    }
  };
};

export const getTransactionsListStartedAction = () => ({
  type: GET_TRANSACTIONS_LIST_STARTED,
});

export const getTransactionsListSuccessAction = (payload) => ({
  type: GET_TRANSACTIONS_LIST_SUCCESS,
  payload,
});

export const getTransactionsListFailureAction = (message) => ({
  type: GET_TRANSACTIONS_LIST_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getTransactionsReportRequestAction = () => {
  return async (dispatch) => {
    dispatch(getTransactionsReportStartedAction());
    try {
      let response = await getTransactionsReportAPI();
      if (response.status === "success") {
        dispatch(
          getTransactionsReportSuccessAction({
            transactionsReport: response.data,
          })
        );
      } else {
        dispatch(
          getTransactionsReportFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      dispatch(getTransactionsReportFailureAction(error.error_description));
    }
  };
};

export const getTransactionsReportStartedAction = () => ({
  type: GET_TRANSACTIONS_REPORT_STARTED,
});

export const getTransactionsReportSuccessAction = (payload) => ({
  type: GET_TRANSACTIONS_REPORT_SUCCESS,
  payload,
});

export const getTransactionsReportFailureAction = (message) => ({
  type: GET_TRANSACTIONS_REPORT_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const refundTransactionRequestAction = ({
  amount,
  description,
  transactionId,
  setOpen,
  setErrors,
  setCount,
  limit,
  offset,
  from,
  to,
}) => {
  return async (dispatch) => {
    dispatch(refundTransactionStartedAction());
    try {
      let response = await refundTransactionAPI({
        amount,
        description,
        transactionId,
      });
      if (response.status === "success") {
        dispatch(refundTransactionSuccessAction());
        dispatch(
          getTransactionsListRequestAction({
            limit,
            offset,
            setCount,
            from,
            to,
          })
        );
        setOpen(false);
      } else {
        dispatch(
          refundTransactionFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      setErrors({ refundAmount: error.message });
      dispatch(refundTransactionFailureAction(error.error_description));
    }
  };
};

export const refundTransactionStartedAction = () => ({
  type: REFUND_TRANSACTION_STARTED,
});

export const refundTransactionSuccessAction = (payload) => ({
  type: REFUND_TRANSACTION_SUCCESS,
  payload,
});

export const refundTransactionFailureAction = (message) => ({
  type: REFUND_TRANSACTION_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getEventsListRequestAction = () => {
  return async (dispatch) => {
    dispatch(getEventsListStartedAction());
    try {
      let response = await getEventsListAPI();
      if (response.status === "success") {
        dispatch(
          getEventsListSuccessAction({
            eventsList: response.data,
          })
        );
      } else {
        dispatch(getEventsListFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getEventsListFailureAction(error.error_description));
    }
  };
};

export const getEventsListStartedAction = () => ({
  type: GET_EVENTS_LIST_STARTED,
});

export const getEventsListSuccessAction = (payload) => ({
  type: GET_EVENTS_LIST_SUCCESS,
  payload,
});

export const getEventsListFailureAction = (message) => ({
  type: GET_EVENTS_LIST_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getTransactionsCoordinatesRequestAction = () => {
  return async (dispatch) => {
    dispatch(getTransactionsCoordinatesStartedAction());
    try {
      let response = await getTransactionsCoordinatesAPI();
      if (response.status === "success") {
        dispatch(
          getTransactionsCoordinatesSuccessAction({
            transactionsCoordinates: response.data,
          })
        );
      } else {
        dispatch(
          getTransactionsCoordinatesFailureAction(
            response.data.error_description
          )
        );
      }
    } catch (error) {
      dispatch(
        getTransactionsCoordinatesFailureAction(error.error_description)
      );
    }
  };
};

export const getTransactionsCoordinatesStartedAction = () => ({
  type: GET_TRANSACTIONS_COORDINATES_STARTED,
});

export const getTransactionsCoordinatesSuccessAction = (payload) => ({
  type: GET_TRANSACTIONS_COORDINATES_SUCCESS,
  payload,
});

export const getTransactionsCoordinatesFailureAction = (message) => ({
  type: GET_TRANSACTIONS_COORDINATES_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getLastTransactionsRequestAction = ({
  countryCode,
  setLastTransactions,
  setOpen,
}) => {
  return async (dispatch) => {
    dispatch(getLastTransactionsStartedAction());
    setOpen(false);
    setLastTransactions({ transactions: [] });
    try {
      let response = await getLastTransactionsAPI({ countryCode });
      if (response.status === "success") {
        dispatch(
          getLastTransactionsSuccessAction({
            transactionsCoordinates: response.data,
          })
        );
        setLastTransactions(response.data);
        setOpen(true);
      } else {
        dispatch(
          getLastTransactionsFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      dispatch(getLastTransactionsFailureAction(error.error_description));
    }
  };
};

export const getLastTransactionsStartedAction = () => ({
  type: GET_LAST_TRANSACTIONS_STARTED,
});

export const getLastTransactionsSuccessAction = (payload) => ({
  type: GET_LAST_TRANSACTIONS_SUCCESS,
  payload,
});

export const getLastTransactionsFailureAction = (message) => ({
  type: GET_LAST_TRANSACTIONS_FAILURE,
  payload: message,
});
