import {
  CREATE_PRODUCT_FAILURE,
  CREATE_PRODUCT_STARTED,
  CREATE_PRODUCT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  DELETE_PRODUCT_STARTED,
  DELETE_PRODUCT_SUCCESS,
  GET_PRODUCTS_LIST_FAILURE,
  GET_PRODUCTS_LIST_STARTED,
  GET_PRODUCTS_LIST_SUCCESS,
} from "../actionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: "",
  productList: [],
  error: "",
};
const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS_LIST_STARTED:
    case CREATE_PRODUCT_STARTED:
    case DELETE_PRODUCT_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case GET_PRODUCTS_LIST_SUCCESS:
      return {
        ...state,
        rType: GET_PRODUCTS_LIST_SUCCESS,
        productList: action.payload.productList,
        error: "",
      };
    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        rType: CREATE_PRODUCT_SUCCESS,
        error: "",
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        rType: DELETE_PRODUCT_SUCCESS,
        error: "",
      };
    case GET_PRODUCTS_LIST_FAILURE:
    case CREATE_PRODUCT_FAILURE:
    case DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        rType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default productReducer;
