import { Typography } from "@mui/material";
import {
  crimsonBlazeColor,
  forestShadowColor,
  primaryColor,
} from "../../assets/colors";
import { EuroIcon3, LiraIcon } from "../../assets/icons";
import i18n from "../../utils/i18n";
import { t } from "i18next";

export const typeStyles = {
  Increase: {
    color: primaryColor,
    iconStyle: { transform: "rotate(180deg)" },
  },
  Decrease: {
    color: crimsonBlazeColor,
    iconStyle: {},
  },
  // Add more types as needed
};

export const currencyIcon = {
  EUR: (
    <EuroIcon3
      width={12}
      height={12}
      color={forestShadowColor}
      style={{ marginRight: "3px" }}
    />
  ),
  TRY: (
    <LiraIcon
      width={12}
      height={12}
      color={forestShadowColor}
      style={{ marginRight: "3px" }}
    />
  ),
  IRR: (
    <Typography sx={{ fontSize: 14, ml: "3px" }}>
      {i18n.t("rialSpecial")}
    </Typography>
  ),
};

export const statusLookup = {
  Pending: t("pendingApproval"),
  Approved: t("confirmed"),
  Declined: t("rejected"),
};
