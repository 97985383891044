import React, {
  createElement,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import MuiTableRow from "@mui/material/TableRow";
import MuiTableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Chip, LinearProgress } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import TableRow from "./Row";
import { CsvBuilder } from "filefy";
import jsPDF from "jspdf";
import "jspdf-autotable";
import usePagination from "@mui/material/usePagination";
// import CustomDropDown from "../CustomDropDown";
import { ListIcon } from "../../assets/icons";
import Pagination from "@mui/material/Pagination";
import MuiPaginationItem from "@mui/material/PaginationItem";
import {
  arcticMistColor,
  forestShadowColor,
  primaryColor,
  silverCloudColor,
  silverMistColor,
  silverSageColor,
  slateGrayColor,
  whisperingSnowColor,
  whiteColor,
} from "../../assets/colors";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useSelector } from "react-redux";
import { api2BaseUrl } from "../../utils/constants";
import Button from "../Button";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { ChevronDownIcon as ArrowDownIcon } from "../../assets/icons";

const EnhancedTable = forwardRef(
  (
    {
      title,
      columns,
      toolbar,
      from,
      to,
      emptyIcon,
      emptyMessage,
      apiEndpoint,
      initialFilters = {},
      datePicker = true,
      keywordSearch,
      disablePagination,
      renderSummary,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const rowsPerPage = 10;
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    //
    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(true);
    // const [rowPerPage, setRowPerPage] = useState(20);
    const [anchorEl, setAnchorEl] = useState(null);
    const [keyword, setKeyword] = useState("");
    const [debouncedKeyword, setDebouncedKeyword] = useState("");

    const accessToken = useSelector((state) => state.auth.token);
    const filteredInitialFilters = Object.keys(initialFilters).reduce(
      (acc, key) => {
        if (initialFilters[key] !== 0) acc[key] = initialFilters[key];
        return acc;
      },
      {}
    );

    useEffect(() => {
      fetchData();
    }, [from, to, page, rowsPerPage, debouncedKeyword]);

    useEffect(() => {
      // Set a delay (e.g., 500ms) before updating the debounced value
      const handler = setTimeout(() => {
        setDebouncedKeyword(keyword);
      }, 500);

      // Clear the timeout if the keyword changes before the delay ends
      return () => {
        clearTimeout(handler);
      };
    }, [keyword]);

    // Expose the fetchData function to the parent component via ref
    useImperativeHandle(ref, () => ({
      refetch: fetchData, // The parent can call refetch to trigger this function
    }));

    const fetchData = async () => {
      setTableLoading(true);

      try {
        // Construct query params
        const queryParams = Object.entries(filteredInitialFilters)
          .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
          .join("&");

        const dateQuery = datePicker
          ? `from=${from.toISOString()}&to=${to.toISOString()}`
          : "";

        const keywordQuery = keywordSearch
          ? `&keyword=${encodeURIComponent(keyword)}`
          : "";

        const finalQuery = [dateQuery, queryParams, keywordQuery]
          .filter(Boolean)
          .join("&");

        const offset = (page - 1) * rowsPerPage;
        const response = await axios.get(
          `${api2BaseUrl}/api/${apiEndpoint}?limit=${rowsPerPage}&offset=${offset}&${finalQuery}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        // Process data with merged keys
        const processItem = (item, columns, index) => {
          const processedItem = { ...item };
          columns.forEach((column) => {
            if (column.mergedKeys) {
              processedItem[column.accessorKey] = column.mergedKeys
                .map((key) => item[key] || "")
                .filter(Boolean)
                .join(" ");
            } else if ("lookup" in column) {
              processedItem[column.accessorKey] =
                column.lookup(response.data.data.records)[index] ||
                item[column.accessorKey];
            }
          });
          return processedItem;
        };

        const filteredData = (
          disablePagination ? response.data.data : response.data.data.records
        ).map((item, index) => processItem(item, columns, index));

        setData(filteredData);
        setTotal(response.data.data.total);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setTableLoading(false);
      }
    };

    const fetchPage = async ({ page, loading }) => {
      setLoading(loading);

      let keys = Object.keys(filteredInitialFilters);
      let values = Object.values(filteredInitialFilters);
      let queryParams = `${keys
        .map((key, index) => `${key}=${values[index]}`)
        .join("&")}`;
      const dateQuery = datePicker
        ? `from=${from.format("YYYY-MM-DD")}&to=${to.format("YYYY-MM-DD")}${
            queryParams ? `&${queryParams}` : ""
          }`
        : queryParams
        ? queryParams
        : "";
      let keywordQuery = keywordSearch ? `&keyword=${keyword}` : "";

      try {
        const response = await axios.get(
          `${api2BaseUrl}/api/${apiEndpoint}?${dateQuery}${keywordQuery}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        // const filteredData = response.data.data.records.map((item) => {
        //   columns.forEach((column) => {
        //     if (column.mergedKeys) {
        //       item[column.accessorKey] = column.mergedKeys
        //         .map((key) => item[key] || "")
        //         .filter(Boolean) // Remove empty strings
        //         .join(" "); // Join with space
        //     }
        //   });
        //   return item;
        // });

        const processItem = (item, columns, index) => {
          const processedItem = { ...item };
          columns.forEach((column) => {
            if (column.mergedKeys) {
              processedItem[column.accessorKey] = column.mergedKeys
                .map((key) => item[key] || "")
                .filter(Boolean)
                .join(" ");
            } else if ("lookup" in column) {
              processedItem[column.accessorKey] = column.lookup(
                response.data.data.records
              )[index][processedItem[column.accessorKey]];
            }
          });
          return processedItem;
        };

        const filteredData = response.data.data.records.map((item, index) =>
          processItem(item, columns, index)
        );
        setLoading(false);
        return filteredData;
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
        throw error;
      }
    };

    const exportTableCSV = async () => {
      const allData = await fetchPage({ loading: "CSV" });
      let filteredColumns = columns.filter(
        (col) => "accessorKey" in col && col.exportable !== false
      );

      let columnData = filteredColumns.map((col) => col.label).reverse();

      let rowData = allData.map((row) =>
        filteredColumns
          .map((col) => {
            let value;
            if (col.exportValue) {
              value = col.exportValue(row);
            } else {
              value = row[col.accessorKey];
            }

            value = value ?? "-";

            if (typeof value === "string") {
              value = value.replace(/\n/g, ", ");
            }

            return value;
          })
          .reverse()
      );

      new CsvBuilder(`report.csv`)
        .setDelimeter(",")
        .setColumns(columnData)
        .addRows(rowData)
        .exportFile();

      setAnchorEl(null);
    };

    async function loadFont() {
      const response = await fetch("/fonts/IRANSansXFaNum-Regular.ttf");
      const fontData = await response.arrayBuffer();
      const base64Font = btoa(
        new Uint8Array(fontData).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );

      return base64Font;
    }

    const exportTablePDF = async () => {
      const allData = await fetchPage({ loading: "PDF" });
      const filteredColumns = columns.filter(
        (col) => "accessorKey" in col && col.exportable !== false
      );
      const columnHeaders = filteredColumns.map((col) => col.label).reverse();

      const tableData = allData.map((row) =>
        filteredColumns
          .map((col) => {
            let value;
            if (col.exportValue) {
              value = col.exportValue(row);
            } else {
              value = row[col.accessorKey];
            }

            return value ?? "-";
          })
          .reverse()
      );

      const pdf = new jsPDF("landscape", "mm", "a4");

      const fontBase64 = await loadFont(); // Load your font

      pdf.addFileToVFS("IRANSansXFaNum-Regular.ttf", fontBase64);
      pdf.addFont("IRANSansXFaNum-Regular.ttf", "IRANSansXFaNum", "normal");
      pdf.setFont("IRANSansXFaNum");

      const pageWidth = pdf.internal.pageSize.getWidth();
      const textWidth = pdf.getTextWidth(t("transactions"));

      // Calculate centered position
      const xPosition = pageWidth - textWidth;

      // Write RTL text
      pdf.text(t("transactions"), xPosition, 20, { align: "right" });

      pdf.autoTable({
        head: [columnHeaders], // Use reversed column headers
        body: tableData, // Use processed data with lookup replacements
        startY: 30,
        tableWidth: "auto", // Ensures proper table rendering in RTL
        margin: { left: 10, right: 10 }, // Adjust margin for RTL positioning
        styles: { font: "IRANSansXFaNum", fontSize: 8, halign: "center" }, // Align text to the right
        didParseCell: function (data) {
          data.cell.styles.dir = "rtl"; // Force RTL direction for content
        },
      });

      pdf.save("report.pdf");
    };

    const handlePageChange = (event, value) => {
      setPage(value);
    };

    return (
      <Box
        component={Paper}
        variant="outlined"
        sx={{ borderColor: whisperingSnowColor, borderRadius: 2.5 }}
      >
        <Box
          sx={(theme) => ({
            px: 2,
            // display: "flex",
            backgroundColor: "inherit",
            borderRadius: "4px",
            flexDirection: "row",
            gap: "16px",
            justifyContent: "space-between",
            // padding: "24px 16px",
            "@media max-width: 768px": {
              flexDirection: "column",
            },
          })}
        >
          <Box
            sx={{
              display: { xs: "none", lg: "flex" },
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ListIcon color={primaryColor} style={{ marginLeft: 10 }} />
              <Typography sx={{ fontSize: 18, fontWeight: 600 }}>
                {title}
              </Typography>
            </Box>
            {toolbar}
            <Button
              text="Excel"
              onClick={exportTableCSV}
              // icon={<DocumentDownloadIcon />}
              sx={{
                borderRadius: "6px",
                fontSize: 12,
                fontWeight: 300,
                height: 40,
                px: 1,
                mr: 1,
              }}
              fullWidth={false}
              loading={loading === "CSV"}
            />
            <Button
              text="PDF"
              onClick={exportTablePDF}
              // icon={<DocumentDownloadIcon />}
              sx={{
                borderRadius: "6px",
                fontSize: 12,
                fontWeight: 300,
                height: 40,
                px: 1,
              }}
              fullWidth={false}
              loading={loading === "PDF"}
            />
            {/* <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={exportTableCSV}>csv</MenuItem>
                <MenuItem
                  component={PDFDownloadLink}
                  // onClick={exportTablePDF}
                  // document={
                  //   <PdfTemplate
                  //     data={{
                  //       title,
                  //       columns: columns.filter(
                  //         (column) => !column.hasOwnProperty("export")
                  //       ),
                  //       items: data,
                  //       from,
                  //       to,
                  //     }}
                  //   />
                  // }
                  fileName={`${
                    title ? `${title.split(" ").join("")}_` : ""
                  }${from}_${to}`}
                >
                  pdf
                </MenuItem>
              </Menu> */}
          </Box>
        </Box>
        <TableContainer sx={{ display: { xs: "none", lg: "block" } }}>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <TableHead>
              <MuiTableRow>
                {columns.map((headCell) => (
                  <MuiTableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "center"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sx={{
                      fontSize: 12,
                      color: slateGrayColor,
                      borderBottom: "none",
                      minWidth: headCell.minWidth,
                      backgroundColor: arcticMistColor,
                    }}
                  >
                    {headCell.label}
                  </MuiTableCell>
                ))}
              </MuiTableRow>
            </TableHead>
            <TableBody>
              {data.map((r, index) => (
                <TableRow
                  columns={columns}
                  data={{ ...r, rowIndex: index }}
                  rowsCount={data.length}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: { lg: "none" }, px: 1.5 }}>
          {data.map((item, index) => (
            <Accordion
              key={index}
              // expanded={expanded === "panel1"}
              // onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                {renderSummary && renderSummary(item)}
              </AccordionSummary>
              <AccordionDetails sx={{ pr: 1, pl: 1.25 }}>
                {columns.map((column, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      py: 2,
                      flexWrap: "wrap",
                    }}
                  >
                    {column.hideLabel ? (
                      column.renderCell(item)
                    ) : (
                      <>
                        <Typography
                          sx={{
                            fontSize: 12,
                            fontWeight: 500,
                            color: slateGrayColor,
                          }}
                        >
                          {column.label}:
                        </Typography>
                        <Typography sx={{ fontSize: 12 }}>
                          {"renderCell" in column
                            ? column.renderCell(item)
                            : item[column.accessorKey]}
                        </Typography>
                      </>
                    )}
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
        <Box
          sx={{
            py: 2,
            mx: 1,
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography sx={{ color: slateGrayColor, fontSize: 12 }}>
            نمایش {data.length} آیتم از {data.count} آیتم جدول
          </Typography>
          <Pagination
            variant="outlined"
            // siblingCount={0}
            count={Math.ceil(total / rowsPerPage)}
            page={page}
            onChange={handlePageChange}
            renderItem={(item) => (
              <PaginationItem
                {...item}
                rowPerPage={rowsPerPage}
                count={data.count}
              />
            )}
            sx={{
              "& .MuiPagination-ul": {
                flexDirection: "row-reverse",
              },
            }}
          />
        </Box>
      </Box>
    );
  }
);

export default EnhancedTable;

const PaginationItem = styled(MuiPaginationItem)((props) => {
  return {
    "&.MuiPaginationItem-root": {
      // flexDirection: "row-reverse",
      height: 27,
      minWidth: 27,
      borderColor: silverMistColor,
      fontWeight: 600,
      // backgroundColor: silverCloudColor,
      // margin: 0,
      // borderRadius: 0,
      // borderTopLeftRadius: props.page === 1 ? 38 : 0,
      // borderBottomLeftRadius: props.page === 1 ? 38 : 0,
      // borderTopRightRadius:
      //   props.page === Math.ceil(props.count / props.rowPerPage) ? 38 : 0,
      // borderBottomRightRadius:
      //   props.page === Math.ceil(props.count / props.rowPerPage) ? 38 : 0,
      fontSize: 11,
      "&.Mui-selected": {
        backgroundColor: primaryColor,
        color: whiteColor,
        // boxShadow: "0px 0px 4.6px 0px rgba(65, 173, 206, 0.76);",
        // borderRadius: 5,
        // height: 27,
        // width: 27,
      },
    },
    "&.MuiPaginationItem-ellipsis": {
      // height: 25,
    },
    "&.MuiPaginationItem-previousNext": {
      borderRadius: "100%",
      marginLeft: 4,
      marginRight: 4,
      minWidth: "auto",
      width: 25,
    },
  };
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  borderRight: `1px solid ${silverCloudColor}`,
  borderLeft: `1px solid ${silverCloudColor}`,
  // "&:not(:last-child)": {
  //   borderBottom: 0,
  // },
  "&:first-child": {
    borderTop: `1px solid ${silverCloudColor}`,
  },
  "&:last-child": {
    borderBottom: `1px solid ${silverCloudColor}`,
  },
  "&:before": {
    display: "none",
  },
  "&:first-of-type": {
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  "&:last-of-type": {
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
  },
  "&:nth-child(even)": {
    backgroundColor: arcticMistColor,
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowDownIcon width={17} color={forestShadowColor} />}
    {...props}
  />
))(({ theme }) => ({
  minHeight: 60,
  paddingLeft: theme.spacing(1.25),
  paddingRight: theme.spacing(1),
  // flexDirection: "row-reverse",
  // "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
  //   transform: "rotate(90deg)",
  // },
  "& .MuiAccordionSummary-content": {
    // marginLeft: theme.spacing(1),
    alignItems: "center",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
