export const SIGNIN_DEFAULT = "SIGNIN_DEFAULT";
export const SIGNIN_STARTED = "SIGNIN_STARTED";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAILURE = "SIGNIN_FAILURE";

export const VERIFY_STARTED = "VERIFY_STARTED";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_FAILURE = "VERIFY_FAILURE";

export const FORGOT_EMAIL_STARTED = "FORGOT_EMAIL_STARTED";
export const FORGOT_EMAIL_SUCCESS = "FORGOT_EMAIL_SUCCESS";
export const FORGOT_EMAIL_FAILURE = "FORGOT_EMAIL_FAILURE";

export const FORGOT_OTP_STARTED = "FORGOT_OTP_STARTED";
export const FORGOT_OTP_SUCCESS = "FORGOT_OTP_SUCCESS";
export const FORGOT_OTP_FAILURE = "FORGOT_OTP_FAILURE";

export const FORGOT_PASSWORD_STARTED = "FORGOT_PASSWORD_STARTED";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const LOGOUT_STARTED = "LOGOUT_STARTED";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const GET_GENERAL_SETTING_DEFAULT = "GET_GENERAL_SETTING_DEFAULT";
export const GET_GENERAL_SETTING_STARTED = "GET_GENERAL_SETTING_STARTED";
export const GET_GENERAL_SETTING_SUCCESS = "GET_GENERAL_SETTING_SUCCESS";
export const GET_GENERAL_SETTING_FAILURE = "GET_GENERAL_SETTING_FAILURE";

export const UPDATE_GENERAL_SETTING_STARTED = "UPDATE_GENERAL_SETTING_STARTED";
export const UPDATE_GENERAL_SETTING_SUCCESS = "UPDATE_GENERAL_SETTING_SUCCESS";
export const UPDATE_GENERAL_SETTING_FAILURE = "UPDATE_GENERAL_SETTING_FAILURE";

export const CHECK_PASSWORD_STARTED = "CHECK_PASSWORD_STARTED";
export const CHECK_PASSWORD_SUCCESS = "CHECK_PASSWORD_SUCCESS";
export const CHECK_PASSWORD_FAILURE = "CHECK_PASSWORD_FAILURE";

export const CHANGE_PASSWORD_STARTED = "CHANGE_PASSWORD_STARTED";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const CREATE_UID_LINK_STARTED = "CREATE_UID_LINK_STARTED";
export const CREATE_UID_LINK_SUCCESS = "CREATE_UID_LINK_SUCCESS";
export const CREATE_UID_LINK_FAILURE = "CREATE_UID_LINK_FAILURE";

export const GET_USER_PROFILE_STARTED = "GET_USER_PROFILE_STARTED";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAILURE = "GET_USER_PROFILE_FAILURE";

export const ADD_BANK_CARD_STARTED = "ADD_BANK_CARD_STARTED";
export const ADD_BANK_CARD_SUCCESS = "ADD_BANK_CARD_SUCCESS";
export const ADD_BANK_CARD_FAILURE = "ADD_BANK_CARD_FAILURE";

export const GET_BANK_CARDS_STARTED = "GET_BANK_CARDS_STARTED";
export const GET_BANK_CARDS_SUCCESS = "GET_BANK_CARDS_SUCCESS";
export const GET_BANK_CARDS_FAILURE = "GET_BANK_CARDS_FAILURE";

export const GET_TICKETS_STARTED = "GET_TICKETS_STARTED";
export const GET_TICKETS_SUCCESS = "GET_TICKETS_SUCCESS";
export const GET_TICKETS_FAILURE = "GET_TICKETS_FAILURE";

export const GET_TICKET_CATEGORIES_STARTED = "GET_TICKET_CATEGORIES_STARTED";
export const GET_TICKET_CATEGORIES_SUCCESS = "GET_TICKET_CATEGORIES_SUCCESS";
export const GET_TICKET_CATEGORIES_FAILURE = "GET_TICKET_CATEGORIES_FAILURE";

export const CREATE_TICKET_STARTED = "CREATE_TICKET_STARTED";
export const CREATE_TICKET_SUCCESS = "CREATE_TICKET_SUCCESS";
export const CREATE_TICKET_FAILURE = "CREATE_TICKET_FAILURE";

export const GET_TRANSACTIONS_LIST_STARTED = "GET_TRANSACTIONS_LIST_STARTED";
export const GET_TRANSACTIONS_LIST_SUCCESS = "GET_TRANSACTIONS_LIST_SUCCESS";
export const GET_TRANSACTIONS_LIST_FAILURE = "GET_TRANSACTIONS_LIST_FAILURE";

export const REFUND_TRANSACTION_STARTED = "REFUND_TRANSACTION_STARTED";
export const REFUND_TRANSACTION_SUCCESS = "REFUND_TRANSACTION_SUCCESS";
export const REFUND_TRANSACTION_FAILURE = "REFUND_TRANSACTION_FAILURE";

export const GET_EVENTS_LIST_STARTED = "GET_EVENTS_LIST_STARTED";
export const GET_EVENTS_LIST_SUCCESS = "GET_EVENTS_LIST_SUCCESS";
export const GET_EVENTS_LIST_FAILURE = "GET_EVENTS_LIST_FAILURE";

export const GET_TRANSACTIONS_REPORT_STARTED =
  "GET_TRANSACTIONS_REPORT_STARTED";
export const GET_TRANSACTIONS_REPORT_SUCCESS =
  "GET_TRANSACTIONS_REPORT_SUCCESS";
export const GET_TRANSACTIONS_REPORT_FAILURE =
  "GET_TRANSACTIONS_REPORT_FAILURE";

export const GET_TICKET_CONVERSATION_STARTED =
  "GET_TICKET_CONVERSATION_STARTED";
export const GET_TICKET_CONVERSATION_SUCCESS =
  "GET_TICKET_CONVERSATION_SUCCESS";
export const GET_TICKET_CONVERSATION_FAILURE =
  "GET_TICKET_CONVERSATION_FAILURE";

export const CREATE_REPLY_TICKET_STARTED = "CREATE_REPLY_TICKET_STARTED";
export const CREATE_REPLY_TICKET_SUCCESS = "CREATE_REPLY_TICKET_SUCCESS";
export const CREATE_REPLY_TICKET_FAILURE = "CREATE_REPLY_TICKET_FAILURE";

export const GET_DASHBOARD_DATA_STARTED = "GET_DASHBOARD_DATA_STARTED";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_FAILURE = "GET_DASHBOARD_DATA_FAILURE";

export const CREATE_FEEDBACK_TICKET_STARTED = "CREATE_FEEDBACK_TICKET_STARTED";
export const CREATE_FEEDBACK_TICKET_SUCCESS = "CREATE_FEEDBACK_TICKET_SUCCESS";
export const CREATE_FEEDBACK_TICKET_FAILURE = "CREATE_FEEDBACK_TICKET_FAILURE";

export const GET_ACCOUNT_EVENTS_STARTED = "GET_ACCOUNT_EVENTS_STARTED";
export const GET_ACCOUNT_EVENTS_SUCCESS = "GET_ACCOUNT_EVENTS_SUCCESS";
export const GET_ACCOUNT_EVENTS_FAILURE = "GET_ACCOUNT_EVENTS_FAILURE";

export const GET_TRANSACTIONS_COORDINATES_STARTED =
  "GET_TRANSACTIONS_COORDINATES_STARTED";
export const GET_TRANSACTIONS_COORDINATES_SUCCESS =
  "GET_TRANSACTIONS_COORDINATES_SUCCESS";
export const GET_TRANSACTIONS_COORDINATES_FAILURE =
  "GET_TRANSACTIONS_COORDINATES_FAILURE";

export const GET_LAST_TRANSACTIONS_STARTED = "GET_LAST_TRANSACTIONS_STARTED";
export const GET_LAST_TRANSACTIONS_SUCCESS = "GET_LAST_TRANSACTIONS_SUCCESS";
export const GET_LAST_TRANSACTIONS_FAILURE = "GET_LAST_TRANSACTIONS_FAILURE";

export const GET_PRODUCTS_LIST_STARTED = "GET_PRODUCTS_LIST_STARTED";
export const GET_PRODUCTS_LIST_SUCCESS = "GET_PRODUCTS_LIST_SUCCESS";
export const GET_PRODUCTS_LIST_FAILURE = "GET_PRODUCTS_LIST_FAILURE";

export const CREATE_PRODUCT_STARTED = "CREATE_PRODUCT_STARTED";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAILURE = "CREATE_PRODUCT_FAILURE";

export const DELETE_PRODUCT_STARTED = "DELETE_PRODUCT_STARTED";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";

export const GET_PERSONAL_PAYLINKS_STARTED = "GET_PERSONAL_PAYLINKS_STARTED";
export const GET_PERSONAL_PAYLINKS_SUCCESS = "GET_PERSONAL_PAYLINKS_SUCCESS";
export const GET_PERSONAL_PAYLINKS_FAILURE = "GET_PERSONAL_PAYLINKS_FAILURE";

export const CREATE_PERSONAL_PAYLINK_STARTED =
  "CREATE_PERSONAL_PAYLINK_STARTED";
export const CREATE_PERSONAL_PAYLINK_SUCCESS =
  "CREATE_PERSONAL_PAYLINK_SUCCESS";
export const CREATE_PERSONAL_PAYLINK_FAILURE =
  "CREATE_PERSONAL_PAYLINK_FAILURE";

export const CANCEL_PERSONAL_PAYLINK_STARTED =
  "CANCEL_PERSONAL_PAYLINK_STARTED";
export const CANCEL_PERSONAL_PAYLINK_SUCCESS =
  "CANCEL_PERSONAL_PAYLINK_SUCCESS";
export const CANCEL_PERSONAL_PAYLINK_FAILURE =
  "CANCEL_PERSONAL_PAYLINK_FAILURE";

export const GET_PRODUCT_PAYLINKS_STARTED = "GET_PRODUCT_PAYLINKS_STARTED";
export const GET_PRODUCT_PAYLINKS_SUCCESS = "GET_PRODUCT_PAYLINKS_SUCCESS";
export const GET_PRODUCT_PAYLINKS_FAILURE = "GET_PRODUCT_PAYLINKS_FAILURE";

export const CREATE_PRODUCT_PAYLINK_STARTED = "CREATE_PRODUCT_PAYLINK_STARTED";
export const CREATE_PRODUCT_PAYLINK_SUCCESS = "CREATE_PRODUCT_PAYLINK_SUCCESS";
export const CREATE_PRODUCT_PAYLINK_FAILURE = "CREATE_PRODUCT_PAYLINK_FAILURE";

export const CANCEL_PRODUCT_PAYLINK_STARTED = "CANCEL_PRODUCT_PAYLINK_STARTED";
export const CANCEL_PRODUCT_PAYLINK_SUCCESS = "CANCEL_PRODUCT_PAYLINK_SUCCESS";
export const CANCEL_PRODUCT_PAYLINK_FAILURE = "CANCEL_PRODUCT_PAYLINK_FAILURE";

export const REGISTER_USER_STARTED = "REGISTER_USER_STARTED";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILURE = "REGISTER_USER_FAILURE";

export const UPDATE_USER_STEP_STARTED = "UPDATE_USER_STEP_STARTED";
export const UPDATE_USER_STEP_SUCCESS = "UPDATE_USER_STEP_SUCCESS";
export const UPDATE_USER_STEP_FAILURE = "UPDATE_USER_STEP_FAILURE";

export const CREATE_WEBGATE_STARTED = "CREATE_WEBGATE_STARTED";
export const CREATE_WEBGATE_SUCCESS = "CREATE_WEBGATE_SUCCESS";
export const CREATE_WEBGATE_FAILURE = "CREATE_WEBGATE_FAILURE";

export const GET_WEBGATES_STARTED = "GET_WEBGATES_STARTED";
export const GET_WEBGATES_SUCCESS = "GET_WEBGATES_SUCCESS";
export const GET_WEBGATES_FAILURE = "GET_WEBGATES_FAILURE";

export const GET_VIRTUAL_ACCOUNTS_STARTED = "GET_VIRTUAL_ACCOUNTS_STARTED";
export const GET_VIRTUAL_ACCOUNTS_SUCCESS = "GET_VIRTUAL_ACCOUNTS_SUCCESS";
export const GET_VIRTUAL_ACCOUNTS_FAILURE = "GET_VIRTUAL_ACCOUNTS_FAILURE";

export const GET_EXCHANGE_RATES_STARTED = "GET_EXCHANGE_RATES_STARTED";
export const GET_EXCHANGE_RATES_SUCCESS = "GET_EXCHANGE_RATES_SUCCESS";
export const GET_EXCHANGE_RATES_FAILURE = "GET_EXCHANGE_RATES_FAILURE";

export const CHECK_EXCHANGE_STARTED = "CHECK_EXCHANGE_STARTED";
export const CHECK_EXCHANGE_SUCCESS = "CHECK_EXCHANGE_SUCCESS";
export const CHECK_EXCHANGE_FAILURE = "CHECK_EXCHANGE_FAILURE";

export const CREATE_EXCHANGE_STARTED = "CREATE_EXCHANGE_STARTED";
export const CREATE_EXCHANGE_SUCCESS = "CREATE_EXCHANGE_SUCCESS";
export const CREATE_EXCHANGE_FAILURE = "CREATE_EXCHANGE_FAILURE";

export const CONFIRM_IRR_REMITTANCE_STARTED = "CONFIRM_IRR_REMITTANCE_STARTED";
export const CONFIRM_IRR_REMITTANCE_SUCCESS = "CONFIRM_IRR_REMITTANCE_SUCCESS";
export const CONFIRM_IRR_REMITTANCE_FAILURE = "CONFIRM_IRR_REMITTANCE_FAILURE";

export const CHECK_IRR_REMITTANCE_STARTED = "CHECK_IRR_REMITTANCE_STARTED";
export const CHECK_IRR_REMITTANCE_SUCCESS = "CHECK_IRR_REMITTANCE_SUCCESS";
export const CHECK_IRR_REMITTANCE_FAILURE = "CHECK_IRR_REMITTANCE_FAILURE";

export const GET_PLANS_HISTORY_STARTED = "GET_PLANS_HISTORY_STARTED";
export const GET_PLANS_HISTORY_SUCCESS = "GET_PLANS_HISTORY_SUCCESS";
export const GET_PLANS_HISTORY_FAILURE = "GET_PLANS_HISTORY_FAILURE";

export const CHANGE_PROFILE_PICTURE_STARTED = "CHANGE_PROFILE_PICTURE_STARTED";
export const CHANGE_PROFILE_PICTURE_SUCCESS = "CHANGE_PROFILE_PICTURE_SUCCESS";
export const CHANGE_PROFILE_PICTURE_FAILURE = "CHANGE_PROFILE_PICTURE_FAILURE";

export const CREATE_USER_COMPANY_STARTED = "CREATE_USER_COMPANY_STARTED";
export const CREATE_USER_COMPANY_SUCCESS = "CREATE_USER_COMPANY_SUCCESS";
export const CREATE_USER_COMPANY_FAILURE = "CREATE_USER_COMPANY_FAILURE";

export const SEND_EMAIL_TOKEN_STARTED = "SEND_EMAIL_TOKEN_STARTED";
export const SEND_EMAIL_TOKEN_SUCCESS = "SEND_EMAIL_TOKEN_SUCCESS";
export const SEND_EMAIL_TOKEN_FAILURE = "SEND_EMAIL_TOKEN_FAILURE";

export const VERIFY_EMAIL_TOKEN_STARTED = "VERIFY_EMAIL_TOKEN_STARTED";
export const VERIFY_EMAIL_TOKEN_SUCCESS = "VERIFY_EMAIL_TOKEN_SUCCESS";
export const VERIFY_EMAIL_TOKEN_FAILURE = "VERIFY_EMAIL_TOKEN_FAILURE";

export const GET_USER_COMPANY_STARTED = "GET_USER_COMPANY_STARTED";
export const GET_USER_COMPANY_SUCCESS = "GET_USER_COMPANY_SUCCESS";
export const GET_USER_COMPANY_FAILURE = "GET_USER_COMPANY_FAILURE";

export const UPGRADE_PLAN_STARTED = "UPGRADE_PLAN_STARTED";
export const UPGRADE_PLAN_SUCCESS = "UPGRADE_PLAN_SUCCESS";
export const UPGRADE_PLAN_FAILURE = "UPGRADE_PLAN_FAILURE";

export const GET_PLUGINS_STARTED = "GET_PLUGINS_STARTED";
export const GET_PLUGINS_SUCCESS = "GET_PLUGINS_SUCCESS";
export const GET_PLUGINS_FAILURE = "GET_PLUGINS_FAILURE";

export const SEND_USER_EMAIL_STARTED = "SEND_USER_EMAIL_STARTED";
export const SEND_USER_EMAIL_SUCCESS = "SEND_USER_EMAIL_SUCCESS";
export const SEND_USER_EMAIL_FAILURE = "SEND_USER_EMAIL_FAILURE";

export const VERIFY_USER_EMAIL_STARTED = "VERIFY_USER_EMAIL_STARTED";
export const VERIFY_USER_EMAIL_SUCCESS = "VERIFY_USER_EMAIL_SUCCESS";
export const VERIFY_USER_EMAIL_FAILURE = "VERIFY_USER_EMAIL_FAILURE";

export const CREATE_USER_PASSWORD_STARTED = "CREATE_USER_PASSWORD_STARTED";
export const CREATE_USER_PASSWORD_SUCCESS = "CREATE_USER_PASSWORD_SUCCESS";
export const CREATE_USER_PASSWORD_FAILURE = "CREATE_USER_PASSWORD_FAILURE";

export const CHANGE_STATUS_WEBGATE_STARTED = "CHANGE_STATUS_WEBGATE_STARTED";
export const CHANGE_STATUS_WEBGATE_SUCCESS = "CHANGE_STATUS_WEBGATE_SUCCESS";
export const CHANGE_STATUS_WEBGATE_FAILURE = "CHANGE_STATUS_WEBGATE_FAILURE";

export const GET_ANNOUNCEMENTS_STARTED = "GET_ANNOUNCEMENTS_STARTED";
export const GET_ANNOUNCEMENTS_SUCCESS = "GET_ANNOUNCEMENTS_SUCCESS";
export const GET_ANNOUNCEMENTS_FAILURE = "GET_ANNOUNCEMENTS_FAILURE";

export const SEEN_ANNOUNCEMENT_STARTED = "SEEN_ANNOUNCEMENT_STARTED";
export const SEEN_ANNOUNCEMENT_SUCCESS = "SEEN_ANNOUNCEMENT_SUCCESS";
export const SEEN_ANNOUNCEMENT_FAILURE = "SEEN_ANNOUNCEMENT_FAILURE";

export const GET_BUSINESS_CATEGORIES_STARTED =
  "GET_BUSINESS_CATEGORIES_STARTED";
export const GET_BUSINESS_CATEGORIES_SUCCESS =
  "GET_BUSINESS_CATEGORIES_SUCCESS";
export const GET_BUSINESS_CATEGORIES_FAILURE =
  "GET_BUSINESS_CATEGORIES_FAILURE";

export const SET_BUSINESS_CATEGORY_STARTED = "SET_BUSINESS_CATEGORY_STARTED";
export const SET_BUSINESS_CATEGORY_SUCCESS = "SET_BUSINESS_CATEGORY_SUCCESS";
export const SET_BUSINESS_CATEGORY_FAILURE = "SET_BUSINESS_CATEGORY_FAILURE";

export const RESEND_REGISTER_CODE_STARTED = "RESEND_REGISTER_CODE_STARTED";
export const RESEND_REGISTER_CODE_SUCCESS = "RESEND_REGISTER_CODE_SUCCESS";
export const RESEND_REGISTER_CODE_FAILURE = "RESEND_REGISTER_CODE_FAILURE";

export const SEND_MOBILE_CODE_STARTED = "SEND_MOBILE_CODE_STARTED";
export const SEND_MOBILE_CODE_SUCCESS = "SEND_MOBILE_CODE_SUCCESS";
export const SEND_MOBILE_CODE_FAILURE = "SEND_MOBILE_CODE_FAILURE";

export const VERIFY_MOBILE_CODE_STARTED = "VERIFY_MOBILE_CODE_STARTED";
export const VERIFY_MOBILE_CODE_SUCCESS = "VERIFY_MOBILE_CODE_SUCCESS";
export const VERIFY_MOBILE_CODE_FAILURE = "VERIFY_MOBILE_CODE_FAILURE";

export const DELETE_ACCOUNT_STARTED = "DELETE_ACCOUNT_STARTED";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

export const EDIT_USER_ADDRESS_STARTED = "EDIT_USER_ADDRESS_STARTED";
export const EDIT_USER_ADDRESS_SUCCESS = "EDIT_USER_ADDRESS_SUCCESS";
export const EDIT_USER_ADDRESS_FAILURE = "EDIT_USER_ADDRESS_FAILURE";

export const GET_ACQUAINTANCES_LIST_STARTED = "GET_ACQUAINTANCES_LIST_STARTED";
export const GET_ACQUAINTANCES_LIST_SUCCESS = "GET_ACQUAINTANCES_LIST_SUCCESS";
export const GET_ACQUAINTANCES_LIST_FAILURE = "GET_ACQUAINTANCES_LIST_FAILURE";

export const CREATE_ACQUAINTANCE_STARTED = "CREATE_ACQUAINTANCE_STARTED";
export const CREATE_ACQUAINTANCE_SUCCESS = "CREATE_ACQUAINTANCE_SUCCESS";
export const CREATE_ACQUAINTANCE_FAILURE = "CREATE_ACQUAINTANCE_FAILURE";

export const GET_POLL_LINK_STARTED = "GET_POLL_LINK_STARTED";
export const GET_POLL_LINK_SUCCESS = "GET_POLL_LINK_SUCCESS";
export const GET_POLL_LINK_FAILURE = "GET_POLL_LINK_FAILURE";

export const CHANGE_POLL_STATUS_STARTED = "CHANGE_POLL_STATUS_STARTED";
export const CHANGE_POLL_STATUS_SUCCESS = "CHANGE_POLL_STATUS_SUCCESS";
export const CHANGE_POLL_STATUS_FAILURE = "CHANGE_POLL_STATUS_FAILURE";

export const CLOSE_MESSAGE_SUCCESS = "CLOSE_MESSAGE_SUCCESS";
