import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Tab,
  Tabs,
  Typography,
  styled,
} from "@mui/material";
import {
  amberBlazeColor,
  arcticMistColor,
  azureBreezeColor,
  blackColor,
  ceruleanCascadeColor,
  crimsonBlazeColor,
  forestShadowColor,
  goldenCreamColor,
  mintMistColor,
  primaryColor,
  rosyGlowColor,
  silverCloudColor,
  slateGrayColor,
  whiteColor,
} from "../../assets/colors";
import {
  ArrowDownIcon2,
  ArrowLeftIcon,
  CalandarOutlineIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseIcon,
  EuroIcon2,
  FilterIcon,
  GlobeIcon,
  LayersIcon,
  RepeatIcon,
  TransactionIcon,
} from "../../assets/icons";
import Table from "../../components/Table";
import Button from "../../components/Button";
import { useEffect, useRef, useState } from "react";
import {
  getEventsListRequestAction,
  getTransactionsListRequestAction,
  getTransactionsReportRequestAction,
  refundTransactionRequestAction,
} from "../../redux/actions/transactionActions";
import { useSelector } from "react-redux";
import moment from "moment-jalaali";
import CustomTextInput from "../../components/CustomTextInput";
import CustomDropDown from "../../components/CustomDropDown";
import { appBarHeight, drawerWidth } from "../../utils/constants";
import CurrencyInput from "../../components/CurrencyInput";
import {
  GET_EVENTS_LIST_STARTED,
  GET_TRANSACTIONS_LIST_STARTED,
  GET_TRANSACTIONS_REPORT_STARTED,
  REFUND_TRANSACTION_STARTED,
} from "../../redux/actionTypes";
import "./style.css";
import CustomRadio from "../../components/CustomRadio";
import { currencyIcon, statusLookup, typeStyles } from "./constants";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AdapterJalali from "@date-io/jalaali";
import useAppHooks from "../../hooks/useAppHooks";
// --------------------------------------------------------------------------------
const TransactionsList = () => {
  moment.loadPersian({ dialect: "persian-modern" });
  const { dispatch, t } = useAppHooks();

  const { transactionsList, eventsList, transactionsReport, rType } =
    useSelector((state) => state.transaction);

  const [page, setPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(20);
  const [eventId, setEventId] = useState(0);
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(0);
  const [refundAmount, setRefundAmount] = useState("");
  const [refundReason, setRefundReason] = useState("");
  const [from, setFrom] = useState(moment().subtract(1, "month"));
  const [to, setTo] = useState(moment());
  const [errors, setErrors] = useState("");
  const [openServices, setOpenServices] = useState("");
  const [openFromDate, setOpenFromDate] = useState(false);
  const [openToDate, setOpenToDate] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const paginationTableRef = useRef();

  const events = [{ name: t("all"), id: 0 }, ...eventsList];
  const loading =
    rType === GET_TRANSACTIONS_LIST_STARTED ||
    rType === GET_TRANSACTIONS_REPORT_STARTED ||
    rType === GET_EVENTS_LIST_STARTED;

  useEffect(() => {
    dispatch(getEventsListRequestAction());
    dispatch(getTransactionsReportRequestAction());
  }, []);

  useEffect(() => {
    !isInitialLoad
      ? paginationTableRef.current.refetch()
      : setIsInitialLoad(false);
  }, [eventId]);

  const renderServiceFilter = (
    <SwipeableDrawer
      anchor="bottom"
      open={openServices}
      // onClose={toggleDrawer(anchor, false)}
      // onOpen={toggleDrawer(anchor, true)}
      onOpen={() => setOpenServices(true)}
      onClose={() => setOpenServices(false)}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
        },
      }}
      // sx={{right:7}}
      disableSwipeToOpen
    >
      {/* {list(anchor)} */}
      <Box
        sx={{
          display: "flex",
          // p: 1,
          alignItems: "center",
          justifyContent: "space-between",
          p: 1.5,
          pb: 1,
        }}
      >
        {/* <Button
          // text={t("clear")}
          fullWidth={false}
          variant="text"
          // color="raspberryPink"
          // onClick={() => setEventType("0")}
        /> */}
        <Typography sx={{ fontWeight: 600 }}>{t("services")}</Typography>
        {/* <Button
          text=""
          fullWidth={false}
          variant="text"
          onClick={() => setOpen(false)}
        /> */}
      </Box>
      <Divider variant="middle" sx={{ borderColor: silverCloudColor }} />
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {events.map((item) => {
          // const labelId = `checkbox-list-label-${value}`;
          return (
            <ListItem
              // key={eventId}
              // secondaryAction={}
              disablePadding
            >
              <ListItemButton
                role={undefined}
                onClick={() => {
                  setEventId(item.id);
                  setOpenServices(false);
                }}
                dense
                disableRipple
              >
                <ListItemIcon sx={{ minWidth: 30 }}>
                  <CustomRadio checked={item.id === eventId} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ sx: { fontWeight: 500 } }}
                  // id={labelId}
                  primary={item.name}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </SwipeableDrawer>
  );

  const renderRefund = () => {
    return (
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            borderRadius: 5,
            boxShadow: "0px 0px 77px 20px #5F5F5F08",
            width: 413,
          },
        }}
        BackdropProps={{
          sx: {
            background: "rgba(218, 218, 218, 0.21)",
            backdropFilter: " blur(9px)",
            left: drawerWidth,
            top: appBarHeight,
            borderLeft: "1px solid #E7EFEC",
          },
        }}
        sx={{ left: drawerWidth, top: appBarHeight }}
        onClose={() => setOpen(false)}
        scroll="body"
      >
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon width={24} color={blackColor} />
        </IconButton>
        <DialogContent
          sx={{
            // height: 226,
            backgroundColor: arcticMistColor,
            fontSize: 16,
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              width: 85,
              height: 85,
              backgroundColor: primaryColor,
              borderRadius: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mx: "auto",
              mb: 2,
              mt: 2,
            }}
          >
            <RepeatIcon color={whiteColor} width={44} height={44} />
          </Box>
          <Typography sx={{ fontWeight: 700, mb: 2 }}>{t("refund")}</Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
            {t("refundProcessingTime")}
          </Typography>
        </DialogContent>
        <DialogContent>
          <CurrencyInput
            label={t("requestAmount")}
            placeholder={t("exampleAmount")}
            onValueChange={(values, sourceInfo) => {
              console.log(values, sourceInfo);

              setRefundAmount(values.value);
            }}
            decimalScale={2}
            thousandSeparator
            endAdornment={<EuroIcon2 width={18} />}
            error={errors.refundAmount}
            helperText={errors.refundAmount}
          />
          <CustomTextInput
            label={t("refundReason")}
            placeholder={t("selectAnOption")}
            onChange={(e) => setRefundReason(e.target.value)}
            value={refundReason}
            // error={errors.refundReason}
            // helperText={errors.refundReason}
          />
          <Button
            text={t("submitRequest")}
            onClick={() => {
              // const errorObj = {};
              // if (refundAmount === "")
              //   errorObj.refundAmount = t(enterRequestedAmount;
              // if (refundReason === "")
              //   errorObj.refundReason = t(enterRefundReason;
              // setErrors(errorObj);
              // if (Object.keys(errorObj).length > 0) return;

              dispatch(
                refundTransactionRequestAction({
                  transactionId: open,
                  amount: refundAmount,
                  description: refundReason,
                  setOpen,
                  setErrors,
                  setCount,
                  offset: page,
                  limit: rowPerPage,
                  from: from.toISOString(),
                  to: to.toISOString(),
                })
              );
            }}
            loading={rType === REFUND_TRANSACTION_STARTED}
            sx={{ borderRadius: 100, mt: 4, mb: 2 }}
            disabled={
              refundReason.trim().length === 0 ||
              refundAmount.trim().length === 0 ||
              Number(refundAmount) === 0
            }
          />
        </DialogContent>
      </Dialog>
    );
  };

  const columns = [
    {
      accessorKey: "event",
      label: t("serviceType"),
      sx: { minWidth: 180 },
    },
    {
      accessorKey: "amount",
      label: t("transactionAmount"),
      renderCell: (row) => {
        const { color, iconStyle } = typeStyles[row.type];

        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {currencyIcon[row.currency]}
            <Typography
              sx={{
                color,
                fontSize: 14,
                fontWeight: 700,
                ml: 0.5,
              }}
            >
              {row.amount}
            </Typography>
            <ArrowDownIcon2 color={color} style={iconStyle} />
          </Box>
        );
      },
    },
    {
      accessorKey: "currency",
      label: t("currencyUnit"),
      sx: { minWidth: 100 },
    },
    {
      accessorKey: "tracking_code",
      label: t("referenceNumber"),
    },
    {
      accessorKey: "status",
      label: t("status"),
      renderCell: (row) => renderStatus(row),
    },
    {
      accessorKey: "source",
      label: t("source"),
    },
    {
      accessorKey: "payer",
      label: t("payer"),
      renderCell: (item) =>
        item.hasPayer
          ? `${item.first_name} ${item.last_name} \n ${item.email}`
          : "-",
      lookup: (data) => {
        let d = data.map((item) => ({
          [item.payer]: item.hasPayer
            ? `${item.first_name} ${item.last_name} \n ${item.email}`
            : "-",
        }));
        return d;
      },
    },
    {
      accessorKey: "date",
      label: t("date"),
      sx: { direction: "rtl", minWidth: 150 },
      renderCell: (item) => Object.values(item.date)[0],
      lookup: (data) => {
        let d = data.map((item) => ({
          [item.date]: moment(item.date).format("jYYYY/jMM/jDD - HH:mm"),
        }));
        return d;
      },
    },
    {
      accessorKey: "refundMoney",
      label: t("refundMoney"),
      renderCell: (row) => {
        if (row.currency_id === 3) return null;
        const allowedEventIds = [1, 3, 9, 12, 21, 22];
        const refund = row.refund;
        if (allowedEventIds.includes(row.event_id)) {
          if (!refund) {
            return (
              <Button
                variant="outlined"
                startIcon={
                  <RepeatIcon width={18} height={18} color={primaryColor} />
                }
                text={t("return")}
                color="silverSage"
                sx={{ color: forestShadowColor, borderRadius: "7px" }}
                onClick={() => setOpen(row.id)}
              />
            );
          }
          const amountUnit = refund.currency_id === 2 ? t("euro") : t("lira");
          switch (refund.status) {
            case "Pending":
              return t("amountPendingApproval", {
                amount: `${refund.amount} ${amountUnit}`,
              });
            case "Approved":
              return t("amountRefunded", {
                amount: `${refund.amount} ${amountUnit}`,
              });
            case "Declined":
              return t("rejectedWithReason", {
                description: row.refund?.admin_description,
              });
            default:
              return null;
          }
        }
        return null;
      },
    },
  ];

  const renderStatus = (row) => {
    switch (row.status) {
      case "Pending":
        return (
          <Chip
            sx={{ borderRadius: "7px" }}
            label={t("pendingApproval")}
            color="goldenCream"
          />
        );
      case "Approved":
        return (
          <Chip
            sx={{ borderRadius: "7px" }}
            label={t("confirmed")}
            color="emeraldOasis"
          />
        );
      case "Declined":
        return (
          <Chip
            sx={{ borderRadius: "7px" }}
            label={t("rejected")}
            color="rosyGlow"
          />
        );
    }
  };

  return (
    <Box sx={{ flex: 1, px: "14px", py: "17px", overflow: "hidden" }}>
      <Box
        sx={{
          display: "flex",
          gap: "11px",
          flexWrap: "wrap",
          mb: "11px",
        }}
      >
        <Box
          sx={{
            backgroundColor: whiteColor,
            height: 112,
            borderRadius: "10px",
            position: "relative",
            overflow: "hidden",
            px: "11px",
            pt: 4,
            flex: "31%",
            display: { lg: "none" },
          }}
        >
          <Box
            sx={{
              width: 49,
              height: 37,
              backgroundColor: azureBreezeColor,
              borderRadius: "52px",
              position: "absolute",
              top: -15,
              left: -18,
              transform: "rotate(-45deg)",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              p: 1,
            }}
          >
            <Box
              sx={{
                backgroundColor: ceruleanCascadeColor,
                width: 7,
                height: 7,
                borderRadius: 100,
              }}
            ></Box>
          </Box>
          <Typography
            sx={{
              fontSize: 30,
              fontWeight: 600,
              color: ceruleanCascadeColor,
            }}
          >
            {transactionsReport.allTransactions}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: 10 }}>
              {t("allTransactions")}
            </Typography>
            {/* <ArrowLeftIcon width={18} height={18} /> */}
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: whiteColor,
            height: 112,
            borderRadius: "10px",
            position: "relative",
            overflow: "hidden",
            // display: "flex",
            // alignItems: "center",
            px: "11px",
            pt: 4,
            flex: "31%",
            display: { lg: "none" },
          }}
        >
          <Box
            sx={{
              width: 49,
              height: 37,
              backgroundColor: goldenCreamColor,
              borderRadius: "52px",
              position: "absolute",
              top: -15,
              left: -18,
              transform: "rotate(-45deg)",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              p: 1,
            }}
          >
            <Box
              sx={{
                backgroundColor: amberBlazeColor,
                width: 7,
                height: 7,
                borderRadius: 100,
              }}
            ></Box>
          </Box>
          <Typography
            sx={{
              fontSize: 30,
              fontWeight: 600,
              color: amberBlazeColor,
            }}
          >
            {transactionsReport.pendingTransactions}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: 10 }}>
              {t("pendingTransactions")}
            </Typography>
            {/* <ArrowLeftIcon width={18} height={18} /> */}
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: whiteColor,
            height: 112,
            borderRadius: "10px",
            position: "relative",
            overflow: "hidden",
            // display: "flex",
            // alignItems: "center",
            px: "11px",
            pt: 4,
            flex: "31%",
            display: { lg: "none" },
          }}
        >
          <Box
            sx={{
              width: 49,
              height: 37,
              backgroundColor: rosyGlowColor,
              borderRadius: "52px",
              position: "absolute",
              top: -15,
              left: -18,
              transform: "rotate(-45deg)",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              p: 1,
            }}
          >
            <Box
              sx={{
                backgroundColor: crimsonBlazeColor,
                width: 7,
                height: 7,
                borderRadius: 100,
              }}
            ></Box>
          </Box>
          <Typography
            sx={{
              fontSize: 30,
              fontWeight: 600,
              color: crimsonBlazeColor,
            }}
          >
            {transactionsReport.complaintTransactions}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: 10 }}>
              {t("complaintTransactions")}
            </Typography>
            {/* <ArrowLeftIcon width={18} height={18} /> */}
          </Box>
        </Box>
        <Box
          sx={{
            background: whiteColor,
            borderRadius: 2.5,
            display: "flex",
            display: { xs: "none", lg: "flex" },
            flex: "32%",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                backgroundColor: azureBreezeColor,
                width: 38,
                height: 67,
                // ml: 4,
                borderTopRightRadius: 50,
                borderBottomRightRadius: 50,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: 13,
                  height: 13,
                  backgroundColor: ceruleanCascadeColor,
                  borderRadius: "100%",
                }}
              ></Box>
            </Box>
          </Box>
          <Box sx={{ mx: 4, my: 2, flex: 1 }}>
            <Typography sx={{ fontWeight: 600 }}>
              {t("totalTransactions")}
            </Typography>
            <Box
              sx={{
                pt: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: 30,
                  fontWeight: 600,
                  color: ceruleanCascadeColor,
                }}
              >
                {transactionsReport.allTransactions}
              </Typography>
              {/* <IconButton>
                <ArrowLeftIcon with={24} />
              </IconButton> */}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            background: whiteColor,
            borderRadius: 2.5,
            display: { xs: "none", lg: "flex" },
            flex: "32%",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                backgroundColor: goldenCreamColor,
                width: 38,
                height: 67,
                // ml: 4,
                borderTopRightRadius: 50,
                borderBottomRightRadius: 50,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: 13,
                  height: 13,
                  backgroundColor: amberBlazeColor,
                  borderRadius: "100%",
                }}
              ></Box>
            </Box>
          </Box>
          <Box sx={{ mx: 4, my: 2, flex: 1 }}>
            <Typography sx={{ fontWeight: 600 }}>
              {t("pendingTransactionsCount")}
            </Typography>
            <Box
              sx={{
                pt: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: 30,
                  fontWeight: 600,
                  color: amberBlazeColor,
                }}
              >
                {transactionsReport.pendingTransactions}
              </Typography>
              {/* <IconButton>
                <ArrowLeftIcon width={24} />
              </IconButton> */}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            background: whiteColor,
            borderRadius: 2.5,
            display: { xs: "none", lg: "flex" },
            flex: "32%",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                backgroundColor: rosyGlowColor,
                width: 38,
                height: 67,
                // ml: 4,
                borderTopRightRadius: 50,
                borderBottomRightRadius: 50,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: 13,
                  height: 13,
                  backgroundColor: crimsonBlazeColor,
                  borderRadius: "100%",
                }}
              ></Box>
            </Box>
          </Box>
          <Box sx={{ mx: 4, my: 2, flex: 1 }}>
            <Typography sx={{ fontWeight: 600 }}>
              {t("complaintTransactionsCount")}
            </Typography>
            <Box
              sx={{
                pt: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: 30,
                  fontWeight: 600,
                  color: crimsonBlazeColor,
                }}
              >
                {transactionsReport.complaintTransactions}
              </Typography>
              {/* <IconButton>
                <ArrowLeftIcon width={24} />
              </IconButton> */}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: whiteColor,
            // px: "12px",
            py: "17px",
            borderRadius: "10px",
            width: "calc(100vw - 28px)",
            display: { lg: "none" },
            flex: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              mb: "19px",
              alignItems: "center",
              pl: "12px",
            }}
          >
            <FilterIcon style={{ marginLeft: 4 }} />
            <Typography sx={{ fontWeight: 600, fontSize: 14, mr: 1 }}>
              {t("filters")}
            </Typography>
            <Box
              sx={{
                flex: 1,
                overflow: "hidden",
                // width: "calc(100vh - 10px)",
              }}
            >
              <Tabs
                // value={tab}
                // onChange={(event, newValue) => navigate(`/account/${newValue}`)}
                aria-label="wrapped label tabs example"
                variant="scrollable"
                scrollButtons={false}
                allowScrollButtonsMobile
                // sx={{ borderBottom: `1px solid ${silverCloudColor}` }}
                indicatorColor="transparent"
                sx={{
                  display: { xs: "flex", lg: "block" },
                  minHeight: "auto",
                  "& .MuiTabs-flexContainer": {
                    flexDirection: { lg: "column" },
                  },
                }}
              >
                <StyledTab
                  value="profile"
                  label={t("serviceTypeLabel", {
                    value: events.find((item) => item.id === eventId).name,
                  })}
                  onClick={() => setOpenServices(true)}
                />
                <StyledTab
                  value="profile"
                  label={`${t("fromDate")}  ${from.format("jDD-jMM-jYYYY")}`}
                  onClick={() => setOpenFromDate(true)}
                />
                <StyledTab
                  value="profile"
                  label={`${t("toDate")}  ${to.format("jDD-jMM-jYYYY")}`}
                  onClick={() => setOpenToDate(true)}
                />
              </Tabs>
            </Box>
          </Box>
        </Box>
        {renderServiceFilter}
        {renderRefund()}
      </Box>
      <Table
        ref={paginationTableRef}
        columns={columns}
        data={transactionsList}
        title={t("transactions")}
        count={count}
        page={page}
        loading={loading}
        emptyIcon={TransactionIcon}
        emptyMessage={t("noTransactions")}
        apiEndpoint={"transaction/all"}
        from={from}
        to={to}
        initialFilters={{ event_id: eventId }}
        toolbar={
          <>
            <Box item sx={{ flexGrow: 1 }}></Box>
            <Box>
              <CustomDropDown
                // label={t(subGroup}
                items={events}
                renderValue={(selectedValue) => {
                  const selectedItem = events.find(
                    (item) => item["id"] === selectedValue
                  );
                  return t("serviceTypeLabel", {
                    value: selectedItem["name"],
                  });
                }}
                schema={{ label: "name", value: "id" }}
                // disabled={category === ""}
                // helperText={errors.subCategory}
                // error={errors.subCategory}
                value={eventId}
                onChange={(e) => setEventId(e.target.value)}
                startAdornment={
                  <InputAdornment>
                    <LayersIcon />
                  </InputAdornment>
                }
                // sx={{ fontSize: 12 }}
                hideHelperText
              />
            </Box>
            <Box sx={{ width: 201, mx: "12px" }}>
              <LocalizationProvider dateAdapter={AdapterJalali}>
                <DatePicker
                  open={openFromDate}
                  onClose={() => setOpenFromDate(false)}
                  value={from}
                  onChange={(newValue) => setFrom(newValue)}
                  renderInput={(params) => (
                    <CustomTextInput
                      {...params}
                      value={from.format("jYYYY-jMM-jDD")}
                      onClick={() => setOpenFromDate(true)}
                      // inputProps={{ readOnly: true }}
                      endAdornment={
                        <InputAdornment position="start">
                          {params.InputProps.endAdornment}
                        </InputAdornment>
                      }
                      // sx={{ marginTop: "0 !important" }}
                      startAdornment={
                        <>
                          <CalandarOutlineIcon />
                          <Typography
                            sx={{
                              fontSize: 12,
                              whiteSpace: "unset",
                              ml: 1,
                            }}
                          >
                            {t("fromDate")}
                          </Typography>
                        </>
                      }
                      inputProps={{ readOnly: true }}
                      sx={{ marginTop: "25px!important" }}
                    />
                  )}
                  components={{
                    OpenPickerIcon: () => <ChevronDownIcon width={18} />,
                    LeftArrowButton: (props) => (
                      <IconButton {...props}>
                        <ChevronRightIcon width={18} />
                      </IconButton>
                    ),
                    RightArrowButton: (props) => (
                      <IconButton {...props}>
                        <ChevronLeftIcon width={18} />
                      </IconButton>
                    ),
                    ActionBar: () => (
                      <DialogActions sx={{ display: { lg: "none" } }}>
                        <Button
                          text={t("close")}
                          onClick={() => setOpenFromDate(false)}
                          fullWidth={false}
                        />
                      </DialogActions>
                    ),
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{ width: 201, mr: "12px" }}>
              <LocalizationProvider dateAdapter={AdapterJalali}>
                <DatePicker
                  open={openToDate}
                  onClose={() => setOpenToDate(false)}
                  value={to}
                  onChange={(newValue) => setTo(newValue)}
                  renderInput={(params) => (
                    <CustomTextInput
                      {...params}
                      value={to.format("jYYYY-jMM-jDD")}
                      onClick={() => setOpenToDate(true)}
                      // inputProps={{ readOnly: true }}
                      endAdornment={
                        <InputAdornment position="start">
                          {params.InputProps.endAdornment}
                        </InputAdornment>
                      }
                      startAdornment={
                        <>
                          <CalandarOutlineIcon />
                          <Typography
                            sx={{
                              fontSize: 12,
                              whiteSpace: "unset",
                              ml: 1,
                            }}
                          >
                            {t("toDate")}
                          </Typography>
                        </>
                      }
                      inputProps={{ readOnly: true }}
                      sx={{ marginTop: "25px!important" }}
                    />
                  )}
                  components={{
                    OpenPickerIcon: () => <ChevronDownIcon width={18} />,
                    LeftArrowButton: (props) => (
                      <IconButton {...props}>
                        <ChevronRightIcon width={18} />
                      </IconButton>
                    ),
                    RightArrowButton: (props) => (
                      <IconButton {...props}>
                        <ChevronLeftIcon width={18} />
                      </IconButton>
                    ),
                    ActionBar: () => (
                      <DialogActions sx={{ display: { lg: "none" } }}>
                        <Button
                          text={t("close")}
                          onClick={() => setOpenToDate(false)}
                          fullWidth={false}
                        />
                      </DialogActions>
                    ),
                  }}
                />
              </LocalizationProvider>
            </Box>
          </>
        }
        renderSummary={(item) => (
          <>
            <Box
              sx={{
                backgroundColor: arcticMistColor,
                width: 36,
                height: 36,
                borderRadius: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mr: 1,
              }}
            >
              <GlobeIcon height={20} width={20} />
            </Box>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
                display: "-webkit-box",
                WebkitLineClamp: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitBoxOrient: "vertical",
                flex: 1,
              }}
            >
              {item.event}
            </Typography>
            <ArrowDownIcon2
              color={typeStyles[item.type].color}
              style={typeStyles[item.type].iconStyle}
            />
            <Typography
              sx={{
                mx: 0.5,
                color: typeStyles[item.type].color,
                fontWeight: 700,
              }}
            >
              {item.amount}
            </Typography>
            <Typography sx={{ fontSize: 12, mr: 0.5 }}>
              {item.currency_id === 3
                ? t("rialSpecial")
                : item.currency_id === 2
                ? t("euroSymbol")
                : t("liraSymbol")}
            </Typography>
          </>
        )}
      />
    </Box>
  );
};

export default TransactionsList;

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  minHeight: 54,
  color: slateGrayColor,
  fontWeight: 500,
  alignItems: "flex-start",
  [theme.breakpoints.down("lg")]: {
    alignItems: "center",
    minHeight: 34,
    fontSize: 12,
  },
  background: arcticMistColor,
  padding: "6.25px 10px",
  // fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(0.8),
  marginLeft: theme.spacing(0.8),
  borderRadius: 38,
  // [theme.breakpoints.up("lg")]: {
  // },
  // color: "rgba(255, 255, 255, 0.7)",
  "&.Mui-selected": {
    color: primaryColor,
    background: mintMistColor,
  },
  // "&.Mui-focusVisible": {
  //   backgroundColor: "rgba(100, 95, 228, 0.32)",
  // },
}));
