import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import {
  ChevronDownIcon,
  CloseIcon,
  CopyIcon,
  DownloadIcon,
  EyeIcon,
  ScanBarcodeIcon,
  ShareIcon,
  ShoppingBagIcon,
  TrashIcon2,
} from "../../../assets/icons";
import {
  arcticMistColor,
  coralSunset,
  darkNavyBlueColor,
  forestShadowColor,
  goldenAmberColor,
  primaryColor,
  silverSageColor,
  slateGrayColor,
  whiteColor,
} from "../../../assets/colors";
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Avatar,
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { useRef, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { statusObj } from "../../Account/constants";
import { useDispatch } from "react-redux";
import { cancelProductPaylinkRequestAction } from "../../../redux/actions/paylinkAction";
import { appBarHeight, drawerWidth } from "../../../utils/constants";
import { QRCodeSVG } from "qrcode.react";

const Item = ({ paylink }) => {
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const [showQrCode, setShowQrCode] = useState(false);
  const [openProductsList, setOpenProductsList] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const svgRef = useRef();

  const handleChange = () => {
    setExpanded((prev) => !prev);
  };

  const downloadQRCode = () => {
    const svgElement = svgRef.current;
    const svgData = new XMLSerializer().serializeToString(svgElement);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const svgSize = svgElement.getBoundingClientRect();
    canvas.width = svgSize.width;
    canvas.height = svgSize.height;

    const img = new Image();
    img.onload = () => {
      ctx.drawImage(img, 0, 0);
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");

      const downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = "qrcode.png";
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: paylink.title,
          text: "Here is something interesting for you!",
          url: paylink.link,
        });
        console.log("Content shared successfully");
      } catch (error) {
        console.error("Error sharing content:", error);
      }
    } else {
      alert("Sharing not supported on this browser");
    }
  };

  const renderDialog = (
    <Dialog
      open={showDialog}
      PaperProps={{
        sx: {
          borderRadius: 5,
          boxShadow: "0px 0px 77px 20px #5F5F5F08",
          width: 452,
        },
      }}
      BackdropProps={{
        sx: {
          background: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(9px)",
          left: { lg: drawerWidth },
          top: appBarHeight,
        },
      }}
      sx={{
        left: { lg: drawerWidth },
        top: appBarHeight,
        // display: { xs: "none", lg: "block" },
      }}
      onClose={() => setShowDialog(false)}
      scroll="body"
    >
      <DialogContent sx={{ pb: 0 }}>
        <Typography>دلایل رد شدن درخواست شما:</Typography>
      </DialogContent>
      <DialogContent>{paylink.admin_description}</DialogContent>
      <DialogActions
        sx={{
          justifyContent: "space-between",
          px: "24px",
          pb: "38px",
          pt: "17px",
        }}
      ></DialogActions>
    </Dialog>
  );

  const renderMerchantCode = (
    <Dialog
      open={showQrCode}
      PaperProps={{
        sx: {
          borderRadius: 5,
          boxShadow: "0px 0px 77px 20px #5F5F5F08;",
          width: 488,
        },
      }}
      BackdropProps={{
        sx: {
          background: "rgba(218, 218, 218, 0.21)",
          backdropFilter: " blur(9px)",
          left: { lg: drawerWidth },
          top: appBarHeight,
          borderLeft: "1px solid #E7EFEC",
        },
      }}
      sx={{ left: { lg: drawerWidth }, top: appBarHeight }}
      onClose={() => setShowQrCode(false)}
      scroll="body"
    >
      <IconButton
        aria-label="close"
        onClick={() => setShowQrCode(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon width={18} />
      </IconButton>
      <DialogTitle>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              height: 40,
              width: 40,
              backgroundColor: arcticMistColor,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "6px",
            }}
          >
            <ScanBarcodeIcon style={{ width: 25, color: primaryColor }} />
          </Box>
          <Typography sx={{ fontWeight: 700, ml: 1 }}>
            QR Code پی‌لینک محصول
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <QRCodeSVG value={paylink.link} level={"H"} ref={svgRef} />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <IconButton
          sx={{ backgroundColor: arcticMistColor }}
          onClick={handleShare}
        >
          <ShareIcon style={{ width: 20 }} />
        </IconButton>
        <IconButton
          sx={{ backgroundColor: arcticMistColor, ml: 1 }}
          onClick={downloadQRCode}
        >
          <DownloadIcon style={{ width: 20 }} />
        </IconButton>
        <IconButton
          sx={{ backgroundColor: arcticMistColor, ml: 1 }}
          onClick={(e) => navigator.clipboard.writeText(paylink.link)}
        >
          <CopyIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      sx={{ mb: 2 }}
      variant="outlined"
    >
      <AccordionSummary>
        <Avatar
          sx={{
            height: { xs: 32, lg: 42 },
            width: { xs: 32, lg: 42 },
            mr: 1.5,
            backgroundColor: forestShadowColor,
          }}
        >
          <Box component={ShoppingBagIcon} sx={{ width: { xs: 16, lg: 24 } }} />
        </Avatar>
        <Box>
          <Typography
            sx={{
              fontSize: { xs: 12, lg: 14 },
              fontWeight: 600,
              mb: 0.5,
            }}
          >
            {paylink.title}
          </Typography>
          <Typography
            sx={{
              color: statusObj[paylink.status]?.color,
              fontSize: { xs: 10, lg: 12 },
              fontWeight: 500,
            }}
          >
            <Box
              sx={{
                width: { xs: 5, lg: 7 },
                height: { xs: 5, lg: 7 },
                borderRadius: 100,
                backgroundColor: statusObj[paylink.status]?.color,
                display: "inline-block",
                mr: 0.4,
              }}
            ></Box>{" "}
            {statusObj[paylink.status]?.label}
          </Typography>
        </Box>
        {paylink.admin_description && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setShowDialog(true);
            }}
            sx={{
              backgroundColor: coralSunset,
              ml: "auto",
              display: { lg: "none" },
            }}
          >
            <EyeIcon width={16} color={whiteColor} />
          </IconButton>
        )}
        <IconButton
          sx={{
            backgroundColor: arcticMistColor,
            ml: { xs: paylink.admin_description ? 1 : "auto", lg: 1 },
            display: { lg: "none" },
          }}
        >
          <ChevronDownIcon
            width={16}
            style={{
              transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
              transition: ".2s transform",
            }}
          />
        </IconButton>
        {paylink.admin_description && (
          <Chip
            label="مشاهده دلایل"
            onClick={(e) => {
              e.stopPropagation();
              setShowDialog(true);
            }}
            color="coralSunset"
            sx={{
              ml: "auto",
              mr: 1,
              display: { xs: "none", lg: "inline-flex" },
            }}
          />
        )}
        <Chip
          label="مشاهده آمار"
          deleteIcon={
            <ChevronDownIcon
              width={16}
              style={{
                transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                transition: ".2s transform",
              }}
            />
          }
          color="arcticMist4"
          onDelete={() => {}}
          sx={{
            ml: paylink.admin_description ? 0 : "auto",
            display: { xs: "none", lg: "flex" },
          }}
        />
        <IconButton
          sx={{ backgroundColor: arcticMistColor, ml: 1 }}
          onClick={(e) => {
            e.stopPropagation();
            navigator.clipboard.writeText(paylink.link);
          }}
        >
          <CopyIcon />
        </IconButton>
        <IconButton
          sx={{ backgroundColor: arcticMistColor, ml: 1 }}
          onClick={(e) => {
            e.stopPropagation();
            setShowQrCode(true);
          }}
        >
          <ScanBarcodeIcon style={{ width: 18 }} />
        </IconButton>
        {/* <IconButton sx={{ backgroundColor: arcticMistColor, ml: 1 }}>
          <EditIcon color={forestShadowColor} width={18} height={18} />
        </IconButton> */}
        <IconButton
          sx={{ backgroundColor: arcticMistColor, ml: 1 }}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(
              cancelProductPaylinkRequestAction({
                id: paylink.id,
              })
            );
          }}
        >
          <TrashIcon2 style={{ width: 18 }} />
        </IconButton>
      </AccordionSummary>

      <AccordionDetails>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 2,
            alignItems: "center",
          }}
        >
          <IconButton onClick={() => setOpenProductsList(!openProductsList)}>
            <ChevronDownIcon
              width={16}
              color={darkNavyBlueColor}
              style={{
                transform: openProductsList ? "rotate(180deg)" : "rotate(0deg)",
                transition: ".2s transform",
              }}
            />
          </IconButton>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: { xs: 14, lg: 16 },
            }}
          >
            Product list
          </Typography>
        </Box>
        {openProductsList && (
          <>
            {paylink.products.map((product, index) => (
              <Box
                key={index}
                sx={{
                  borderBottom: `1px solid ${silverSageColor}`,
                  py: 1,
                  display: { lg: "none" },
                  mb: "28px",
                  mt: index === 0 ? "28px" : 0,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Box>
                    <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
                      {product.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 10,
                        color: slateGrayColor,
                        textAlign: "right",
                      }}
                    >
                      {product.detail}
                    </Typography>
                  </Box>
                  <Avatar
                    src={product.image}
                    sx={{
                      width: 32,
                      height: 32,
                      borderRadius: 1.25,
                      ml: 1,
                    }}
                  />
                </Box>
                <Box sx={{ mb: "12px" }}>
                  <Typography sx={{ fontSize: 12 }}>توضیحات</Typography>
                  <Typography sx={{ textAlign: "right", fontSize: 12 }}>
                    {product.description}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: 12 }}>قیمت</Typography>
                  <Typography
                    sx={{
                      textAlign: "right",
                      fontSize: 12,
                    }}
                  >
                    €{product.amount}
                  </Typography>
                </Box>
              </Box>
            ))}

            <TableContainer sx={{ display: { xs: "none", lg: "block" } }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  {paylink.products.map((product, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        // align="right"
                        sx={{ borderColor: silverSageColor, width: 60 }}
                      >
                        <Typography sx={{ fontSize: 12 }}>قیمت:</Typography>
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: 600,
                          }}
                        >
                          €{product.amount}
                        </Typography>
                      </TableCell>

                      <TableCell
                        // align="right"
                        sx={{ borderColor: silverSageColor, width: 60 }}
                      >
                        <Typography sx={{ fontSize: 12 }}>توضیحات:</Typography>
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: 600,
                          }}
                        >
                          {product.description}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          borderColor: silverSageColor,
                          width: 180,
                          pr: 0,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <Box>
                            <Typography>{product.title}</Typography>
                            <Typography
                              sx={{
                                fontSize: 12,
                                color: slateGrayColor,
                                textAlign: "right",
                              }}
                            >
                              {product.detail}
                            </Typography>
                          </Box>
                          <Avatar
                            src={product.image}
                            sx={{
                              width: 50,
                              height: 50,
                              borderRadius: 1.25,
                              ml: 1,
                            }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        <Box
          sx={{
            mt: 4,
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
          }}
        >
          <Box sx={{ width: { lg: 300 }, order: { xs: 2, lg: 1 } }}>
            <Box
              sx={{
                backgroundColor: arcticMistColor,
                borderRadius: 1.5,
                p: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 2,
              }}
            >
              <Typography>حجم تراکنش امروز:</Typography>
              <Typography sx={{ color: primaryColor }}>
                {paylink.today_paylink_amount}
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: arcticMistColor,
                borderRadius: 1.5,
                p: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 2,
              }}
            >
              <Typography>تعداد تراکنش امروز:</Typography>
              <Typography sx={{ color: goldenAmberColor }}>
                {paylink.today_paylink_count}
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: arcticMistColor,
                borderRadius: 1.5,
                p: 1,
                // display: "flex",
                // alignItems: "center",
                // justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: 700,
                  fontSize: 14,
                  mb: 1,
                }}
              >
                هفته گذشته
              </Typography>
              <Box sx={{ display: "flex", textAlign: "center" }}>
                <Box sx={{ p: 2, flex: 0.5 }}>
                  <Typography
                    sx={{
                      color: primaryColor,
                      fontSize: 25,
                      fontWeight: 700,
                      lineHeight: 1.7,
                    }}
                  >
                    {paylink.week_paylink_amount}
                  </Typography>
                  <Typography sx={{ fontSize: 10 }}>جمع کل فروش</Typography>
                </Box>
                <Box>
                  <Divider orientation="vertical" />
                </Box>
                <Box sx={{ p: 2, flex: 0.5, textAlign: "center" }}>
                  <Typography
                    sx={{
                      color: goldenAmberColor,
                      fontSize: 30,
                      fontWeight: 700,
                    }}
                  >
                    {paylink.week_paylink_count}
                  </Typography>
                  <Typography sx={{ fontSize: 10 }}>تراکنش</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              pl: { lg: 3 },
              height: { xs: 120, lg: "unset" },
              width: "100%",
              order: 1,
              mb: { xs: 5, lg: 0 },
            }}
          >
            <ResponsiveContainer>
              <AreaChart
                // width={492}
                // height={250}
                data={paylink.paylink_week}
                margin={{ top: 10, right: 30, left: -30, bottom: 0 }}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor={goldenAmberColor}
                      stopOpacity={0.1}
                    />
                    <stop
                      offset="95%"
                      stopColor={goldenAmberColor}
                      stopOpacity={0}
                    />
                  </linearGradient>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor={primaryColor}
                      stopOpacity={0.1}
                    />
                    <stop
                      offset="95%"
                      stopColor={primaryColor}
                      stopOpacity={0}
                    />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="uv"
                  stroke={goldenAmberColor}
                  fillOpacity={1}
                  fill="url(#colorUv)"
                />
                <Area
                  type="monotone"
                  dataKey="pv"
                  stroke={primaryColor}
                  fillOpacity={1}
                  fill="url(#colorPv)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      </AccordionDetails>
      {renderDialog}
      {renderMerchantCode}
    </Accordion>
  );
};

export default Item;

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `1px solid ${silverSageColor}`,
  borderRadius: "12px!important",
  "&::before": {
    display: "none",
  },
  // borderRight: `1px solid ${silverCloudColor}`,
  // borderLeft: `1px solid ${silverCloudColor}`,
  // "&:not(:last-child)": {
  //   borderBottom: 0,
  // },
  // "&:first-child": {
  //   borderTop: `1px solid ${silverCloudColor}`,
  // },
  // "&:last-child": {
  //   borderBottom: `1px solid ${silverCloudColor}`,
  // },
  // "&:first-of-type": {
  //   borderTopLeftRadius: 15,
  //   borderTopRightRadius: 15,
  // },
  // "&:last-of-type": {
  //   borderBottomLeftRadius: 15,
  //   borderBottomRightRadius: 15,
  // },
  // "&:nth-child(even)": {
  //   backgroundColor: arcticMistColor,
  // },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    // expandIcon={
    //   <Box
    //     sx={{
    //       width: 35,
    //       height: 35,
    //       backgroundColor: arcticMistColor,
    //       display: "flex",
    //       alignItems: "center",
    //       justifyContent: "center",

    //       borderRadius: 100,
    //     }}
    //   >
    //     <ArrowDownIcon width={17} color={forestShadowColor} />
    //   </Box>
    // }
    {...props}
  />
))(({ theme }) => ({
  minHeight: 58,
  paddingLeft: theme.spacing(1.25),
  paddingRight: theme.spacing(1),
  flexDirection: "row-reverse",
  // "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
  //   transform: "rotate(90deg)",
  // },
  "& .MuiAccordionSummary-content": {
    // marginLeft: theme.spacing(1),
    alignItems: "center",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
