import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { ClipboardIcon, LighthouseIcon } from "../../assets/icons";
import {
  arcticMistColor,
  darkNavyBlueColor,
  primaryColor,
  silverSageColor,
} from "../../assets/colors";
import Button from "../../components/Button";
import { ONBOARDING } from "../routes";
import useAppHooks from "../../hooks/useAppHooks";
import { appBarHeight, drawerWidth } from "../../utils/constants";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  createAcquaintanceRequestAction,
  getAcquaintancesListRequestAction,
} from "../../redux/actions/userActions";
import { CREATE_ACQUAINTANCE_STARTED } from "../../redux/actionTypes";

const Welcome = () => {
  const { acquaintances, rType, isAcquaintanceSelected } = useSelector(
    (state) => state.user
  );
  const [acquaintanceId, setAcquaintanceId] = useState([]);
  const { t, navigate, dispatch } = useAppHooks();

  // useEffect(() => {
  //   dispatch(getAcquaintancesListRequestAction());
  // }, []);

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ textAlign: "center", mb: 10 }}>
        <Box
          sx={{
            width: 172,
            height: 172,
            backgroundColor: arcticMistColor,
            borderRadius: 100,
            overflow: "hidden",
            mb: "29px",
            mx: "auto",
          }}
        >
          <LighthouseIcon
            width={157.4}
            color={primaryColor}
            style={{ position: "relative", left: -10, bottom: -6 }}
          />
        </Box>
        <Typography sx={{ fontSize: 22, fontWeight: 600, mb: 2 }}>
          {t("welcomeToYekpay")}
        </Typography>
        <Typography sx={{ fontWeight: 500, mb: 3 }}>
          {t("letsGetStarted")}
        </Typography>
        <Button
          text={t("completeUserProfile")}
          sx={{ borderRadius: 100 }}
          onClick={() => navigate(ONBOARDING)}
        />
      </Box>
      <Dialog
        // open={isAcquaintanceSelected}
        PaperProps={{
          sx: {
            borderRadius: 5,
            boxShadow: "0px 0px 77px 20px #5F5F5F08",
            width: 452,
          },
        }}
        BackdropProps={{
          sx: {
            background: "rgba(0, 0, 0, 0.5)",
            backdropFilter: "blur(9px)",
            left: { lg: drawerWidth },
            top: appBarHeight,
          },
        }}
        sx={{
          left: { lg: drawerWidth },
          top: appBarHeight,
          // display: { xs: "none", lg: "block" },
        }}
        // onClose={() => setOpen(false)}
        scroll="body"
      >
        <DialogContent>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                backgroundColor: silverSageColor,
                width: 85,
                height: 85,
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "15px",
              }}
            >
              <ClipboardIcon width={43} height={43} color={darkNavyBlueColor} />
            </Box>
          </Box>
          <Typography sx={{ mt: 4, mb: 1, fontWeight: 600 }}>
            {t("howDidYouKnowUs")}
          </Typography>
          <FormGroup>
            {acquaintances.map((item) => (
              <FormControlLabel
                key={item.id}
                control={<Checkbox />}
                label={item.name}
                checked={acquaintanceId.includes(item.id)}
                onChange={() =>
                  setAcquaintanceId(
                    (prev) =>
                      prev.includes(item.id)
                        ? prev.filter((id) => id !== item.id) // Uncheck if already selected
                        : [...prev, item.id] // Add to selection if not selected
                  )
                }
                componentsProps={{ typography: { fontSize: 14 } }}
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 2, pt: 0 }}>
          <Button
            text={t("register")}
            onClick={() =>
              dispatch(createAcquaintanceRequestAction({ ids: acquaintanceId }))
            }
            loading={rType === CREATE_ACQUAINTANCE_STARTED}
            disabled={acquaintanceId.length === 0}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Welcome;
