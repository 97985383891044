import {
  Avatar,
  Box,
  IconButton,
  styled,
  Typography,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from "@mui/material";
import {
  arcticMistColor,
  forestShadowColor,
  silverSageColor,
  slateGrayColor,
} from "../../../assets/colors";
import {
  ChevronDownIcon,
  ShoppingBagIcon,
  TrashIcon2,
} from "../../../assets/icons";
import { ChevronDownIcon as ArrowDownIcon } from "../../../assets/icons";

const Item = ({ product, onDelete }) => {
  return (
    <Accordion sx={{ mb: 2, display: { lg: "none" } }}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <IconButton
          sx={{ backgroundColor: arcticMistColor, ml: 1 }}
          onClick={(event) => {
            event.stopPropagation();
            onDelete(event);
          }}
        >
          <TrashIcon2 style={{ width: 18 }} />
        </IconButton>
        <Box sx={{ flex: 1, textAlign: "right", width: 150, ml: 1 }}>
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 500,
              mb: 0.5,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              direction: "rtl",
            }}
          >
            {product.title}
          </Typography>
          <Typography
            sx={{
              fontSize: 10,
              color: slateGrayColor,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              direction: "rtl",
            }}
          >
            {product.detail}
          </Typography>
        </Box>
        <Avatar
          sx={{
            height: 32,
            width: 32,
            backgroundColor: forestShadowColor,
            borderRadius: "5px",
            ml: "9px",
          }}
          src={product.image}
        >
          <ShoppingBagIcon width={24} />
        </Avatar>
      </AccordionSummary>
      <AccordionDetails sx={{ pr: 1, pl: 1.25 }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              py: 1,
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            <Typography
              sx={{ fontSize: 12, fontWeight: 500, color: slateGrayColor }}
            >
              نام محصول
            </Typography>
            <Typography
              sx={{ fontSize: 14, fontWeight: 500, textAlign: "right" }}
            >
              {product.title}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: 1,
              py: 1,
            }}
          >
            <Typography
              sx={{ fontSize: 12, fontWeight: 500, color: slateGrayColor }}
            >
              توضیحات
            </Typography>
            <Typography
              sx={{ fontSize: 14, fontWeight: 500, textAlign: "right" }}
            >
              {product.description}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              py: 1,
            }}
          >
            <Typography
              sx={{ fontSize: 12, fontWeight: 500, color: slateGrayColor }}
            >
              قیمت
            </Typography>
            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
              €{product.amount}
            </Typography>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default Item;

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `1px solid ${silverSageColor}`,
  borderRadius: "12px!important",
  "&::before": {
    display: "none",
  },
  // borderRight: `1px solid ${silverCloudColor}`,
  // borderLeft: `1px solid ${silverCloudColor}`,
  // "&:not(:last-child)": {
  //   borderBottom: 0,
  // },
  // "&:first-child": {
  //   borderTop: `1px solid ${silverCloudColor}`,
  // },
  // "&:last-child": {
  //   borderBottom: `1px solid ${silverCloudColor}`,
  // },
  // "&:first-of-type": {
  //   borderTopLeftRadius: 15,
  //   borderTopRightRadius: 15,
  // },
  // "&:last-of-type": {
  //   borderBottomLeftRadius: 15,
  //   borderBottomRightRadius: 15,
  // },
  // "&:nth-child(even)": {
  //   backgroundColor: arcticMistColor,
  // },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <Box
        sx={{
          width: 35,
          height: 35,
          backgroundColor: arcticMistColor,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          borderRadius: 100,
        }}
      >
        <ArrowDownIcon width={17} color={forestShadowColor} />
      </Box>
    }
    {...props}
  />
))(({ theme }) => ({
  minHeight: 58,
  paddingLeft: theme.spacing(1.25),
  paddingRight: theme.spacing(1),
  flexDirection: "row-reverse",
  // "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
  //   transform: "rotate(90deg)",
  // },
  "& .MuiAccordionSummary-content": {
    // marginLeft: theme.spacing(1),
    alignItems: "center",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
