import {
  Avatar,
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import {
  arcticMistColor,
  mistyRoseColor,
  primaryColor,
  slateGrayColor,
  whisperingSnowColor,
  whiteColor,
} from "../../../assets/colors";
import { ImageIcon, ListIcon, TrashIcon2 } from "../../../assets/icons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  createProductRequestAction,
  deleteProductRequestAction,
  getProductsListRequestAction,
} from "../../../redux/actions/productActions";
import Table2 from "../../../components/Table2";
import AddProduct from "./AddProduct";
import Item from "./item";
import { appBarHeight, dmSans, drawerWidth } from "../../../utils/constants";
import useAppHooks from "../../../hooks/useAppHooks";
import Button from "../../../components/Button";
import { DELETE_PRODUCT_STARTED } from "../../../redux/actionTypes";

const ProductsList = () => {
  const { dispatch, t } = useAppHooks();

  const { productList, rType } = useSelector((state) => state.product);
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [page, setPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [count, setCount] = useState(1);

  useEffect(() => {
    dispatch(
      getProductsListRequestAction({
        limit: rowPerPage,
        offset: page,
        setCount,
      })
    );
  }, []);

  const renderDeleteConfirm = (
    <Dialog
      open={openConfirm}
      PaperProps={{
        sx: {
          borderRadius: 5,
          boxShadow: "0px 0px 77px 20px #5F5F5F08",
        },
      }}
      maxWidth="sm"
      BackdropProps={{
        sx: {
          background: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(9px)",
          left: { lg: drawerWidth },
          top: appBarHeight,
        },
      }}
      sx={{
        left: { lg: drawerWidth },
        top: appBarHeight,
        // display: { xs: "none", lg: "block" },
      }}
      onClose={() => setOpenConfirm(false)}
      scroll="body"
    >
      <Box
        sx={{
          p: "20px",
          borderRadius: "20px",
          width: { lg: 488 },
        }}
      >
        <Box
          sx={{
            display: { lg: "flex" },
            // textAlign: "center",
            alignItems: "center",
            mb: "32px",
          }}
        >
          <Box
            sx={{
              backgroundColor: mistyRoseColor,
              width: 85,
              height: 85,
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "15px",
              mb: { xs: "32px", lg: 0 },
            }}
          >
            <TrashIcon2 width={43} height={43} color={primaryColor} />
          </Box>
          <Box sx={{ flex: 1, ml: 2 }}>
            <Typography sx={{ fontWeight: 700, mb: 1 }}>
              حدف محصول {openConfirm.title}
            </Typography>
            <Typography
              sx={{ lineHeight: 1.8, fontSize: 14, color: slateGrayColor }}
            >
              آیا مطمئن هستید که می‌خواهید این محصول را حذف کنید؟
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            text="تایید و حذف محصول"
            onClick={() =>
              dispatch(
                deleteProductRequestAction({
                  productId: openConfirm.id,
                  setCount,
                  setOpenConfirm,
                })
              )
            }
            color="fireEngineRed"
            loading={rType === DELETE_PRODUCT_STARTED}
          />
          <Button
            text="انصراف و بازگشت"
            onClick={() => setOpenConfirm(false)}
            color="arcticMist"
          />
        </Box>
      </Box>
    </Dialog>
  );

  const columns = [
    {
      field: "title", //access nested data with dot notation
      label: "نام محصول",
      renderCell: (row) => (
        <Box
          sx={{
            display: "flex",
            // justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{ backgroundColor: arcticMistColor }}
            src={row.image}
            variant="rounded"
          >
            <ImageIcon />
          </Avatar>
          <Box sx={{ flex: 1, ml: 1 }}>
            <Typography sx={{ mb: 0.5 }}>{row.title}</Typography>
            <Typography sx={{ fontSize: 12, color: slateGrayColor }}>
              {row.detail}
            </Typography>
          </Box>
        </Box>
      ),
    },
    // {
    //   field: "detail",
    //   label: "جزئیات",
    // },
    {
      field: "description",
      label: "توضیحات",
    },
    {
      field: "amount",
      label: "قیمت",
      renderCell: (row) => `€${row.amount}`,
      sx: { fontFamily: dmSans, fontWeight: 600 },
    },
    {
      field: "price",
      label: "عملیات",
      renderCell: (row) => (
        <Chip
          label="حذف محصول"
          sx={{
            fontSize: 12,
            height: "24px!important",
            fontWeight: 500,
          }}
          onClick={() => setOpenConfirm(row)}
          color="peachPink"
        />
      ),
    },
  ];

  return (
    <Box sx={{ flex: 1, p: { xs: "15px", lg: 3 } }}>
      <Box>
        <Box
          sx={{
            backgroundColor: whiteColor,
            p: { lg: 3, xs: 2 },
            border: `1px solid ${whisperingSnowColor}`,
            borderRadius: 2.5,
          }}
        >
          <Box
            sx={{
              // display: { xs: "none", lg: "flex" },
              display: "flex",
              alignItems: "center",
              mb: 3,
            }}
          >
            <ListIcon color={primaryColor} />{" "}
            <Typography
              sx={{ ml: 1, fontWeight: 600, fontSize: { xs: 16, lg: 18 } }}
            >
              محصولات
            </Typography>{" "}
            <Chip
              label="+ افزودن محصول جدید"
              color="brightTurquoise"
              sx={{ ml: "auto" }}
              onClick={() => setOpen(true)}
            />
          </Box>
          <Table2
            data={productList}
            columns={columns}
            count={count}
            onChangePage={(page) => setPage(page)}
          />
          {productList.map((p, index) => (
            <Item key={index} product={p} onDelete={() => setOpenConfirm(p)} />
          ))}
        </Box>
      </Box>
      <AddProduct
        open={open}
        setOpen={setOpen}
        setCount={setCount}
        onAdd={({ formData, setState }) => {
          dispatch(
            createProductRequestAction({
              formData,
              setOpen,
              setState,
              setCount,
            })
          );
        }}
      />
      {renderDeleteConfirm}
    </Box>
  );
};

export default ProductsList;

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  //   "& .MuiTabs-indicator": {
  //     backgroundColor: "#1890ff",
  //   },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    padding: "10px 8px",
    fontSize: 12,
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    // fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    // "&:hover": {
    //   color: "#40a9ff",
    //   opacity: 1,
    // },
    "&.Mui-selected": {
      //   color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);
